<template>
  <div class="contents userInfo" v-loading="loading">
    <div class="title flexB">
      <h2>회원정보</h2>
    </div>
    <div class="box one">
      <div class="flex">
        <p>권한</p>
        <label>
          <input
            type="radio"
            name="Role"
            value="true"
            v-model="isAdmin"
          />관리자
        </label>
        <label>
          <input type="radio" name="Role" value="false" v-model="isAdmin" />일반
        </label>
      </div>
      <!-- <div class="flex">
        <p>회원등급</p>
        <label> <input type="radio" name="rank" value="SILVER" v-model="level" />실버 </label>
        <label> <input type="radio" name="rank" value="GOLD" v-model="level" />골드 </label>
        <label> <input type="radio" name="rank" value="DIAMOND" v-model="level" />다이아몬드 </label>
      </div>-->
      <div class="flex">
        <p>회원등급</p>
        <span>
          {{
            level == "SILVER"
              ? "실버"
              : level == "GOLD"
              ? "골드"
              : level == "DIAMOND"
              ? "다이아몬드"
              : ""
          }}
        </span>
      </div>
      <div class="flex">
        <p>보유 포인트</p>
        <input type="text" v-model="point" />
      </div>
      <div class="flex">
        <p>이름</p>
        <input type="text" v-model="username" />
      </div>
      <div class="flex">
        <p>이메일</p>
        <input type="text" v-model="email" />
      </div>
      <div class="flex">
        <p>생년월일</p>
        <input type="text" v-model="birth" />
      </div>
      <div class="flex">
        <p>성별</p>
        <label> <input type="radio" value="M" v-model="gender" />남 </label>
        <label> <input type="radio" value="F" v-model="gender" />여 </label>
      </div>
      <div class="flex">
        <p>결혼</p>
        <label>
          <input type="radio" :value="true" checked v-model="isMarried" />기혼
        </label>
        <label>
          <input type="radio" :value="false" checked v-model="isMarried" />미혼
        </label>
      </div>
      <div class="flex">
        <p>직업</p>
        <label>
          <input
            type="radio"
            value="SELFEMPLOYED"
            checked
            v-model="job"
          />자영업
        </label>
        <label>
          <input type="radio" value="EMPLOYEE" checked v-model="job" />직장인
        </label>
        <label>
          <input type="radio" value="UNEMPLOYED" checked v-model="job" />무직
        </label>
      </div>
      <div class="flex">
        <p>연락처</p>
        <input type="text" v-model="mobileNo" />
      </div>

      <div class="flex">
        <p>가입일</p>
        <span>{{ moment(createdAt).format("YYYY년 MM월 DD일 HH:mm") }}</span>
      </div>
      <div class="flex">
        <p>주소</p>
        <input type="text" v-model="address" />
      </div>

      <div class="buttonWrap">
        <button class="basic" @click="submit">수정</button>
        <router-link to="/admin/users">
          <button class="right line">목록</button>
        </router-link>
      </div>
    </div>
    <div class="box one">
      <h2 class="bold">설문참여 내역</h2>
      <table>
        <colgroup>
          <col style="width: 10%" />
          <col style="width: 35%" />
          <col style="width: 25%" />
          <col style="width: 20%" />
          <col style="width: 10%" />
        </colgroup>
        <tr>
          <th>번호</th>
          <!-- <th>설문제목</th> -->
          <th>제품명</th>
          <th>참여일</th>
          <th>입력가격</th>
          <th>당첨여부</th>
        </tr>
        <tr v-for="(data, i) in surveyList" :key="i">
          <td>{{ i + 1 }}</td>
          <td>{{ data.post.title }}</td>
          <td>{{ moment(data.createdAt).format("YYYY.MM.DD") }}</td>
          <td>{{ numberFormat(data.amount) }}원</td>
          <td>
            <span :class="{ primary: data.win }">
              {{ data.win == true ? "당첨" : "미당첨" }}
            </span>
          </td>
        </tr>
      </table>
      <NoData v-if="surveyList.length == 0" />
      <div class="pagination">
        <el-pagination
          layout="prev, pager, next"
          :total="total"
          :page-size="10"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { fetchUserDetails, updateUserDetails } from "@/api/admin";
import { fetchUserSurveyList } from "@/api/survey";
import { format } from "@/mixins/format";
import NoData from "@/components/contents/Nodata";

export default {
  mixins: [format],
  name: "UserManage",
  components: { NoData },
  data() {
    return {
      moment: moment,
      inputMode: "",
      searchWord: "",
      total: 0,
      currentPage: 1,
      userId: "",
      isAdmin: false,
      isMarried: false,
      username: "",
      email: "",
      mobileNo: "",
      createdAt: "",
      updatedAt: "",
      gender: "",
      job: "",
      level: "",
      address: "",
      birth: "",
      point: "",
      surveyList: [],
      loading: false,
    };
  },
  created() {
    this.$store.dispatch("SET_NAVBAR", 1);
  },
  mounted() {
    this.loading = true;

    this.userId = this.$route.query.id;
    this.getUserDetail();
  },
  methods: {
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getUserDetail();
    },
    getUserDetail() {
      fetchUserDetails(this.userId).then((res) => {
        if (res.data.status == 200) {
          if (res.data.status == 200) {
            this.isAdmin = res.data.data.isAdmin;
            this.username = res.data.data.username;
            this.email = res.data.data.email;
            this.mobileNo = res.data.data.mobileNo;
            this.createdAt = res.data.data.createdAt;
            this.updatedAt = res.data.data.updatedAt;
            this.gender = res.data.data.gender;
            this.job = res.data.data.job;
            this.level = res.data.data.level;
            this.address = res.data.data.address;
            this.birth = res.data.data.birth;
            this.point = res.data.data.point;
            this.isMarried = res.data.data.isMarried;
            this.loading = false;
          } else if (res.data.status == 409) {
            this.$router.push("/");
            return alert(res.data.message);
          }
        } else {
          alert(res.data.message);
        }
      });
      let params = {
        userId: this.userId,
        page: this.currentPage,
      };
      fetchUserSurveyList(params).then((res) => {
        if (res.data.status == 200) {
          this.surveyList = res.data.surveylist;
          this.total = res.data.total;
          this.currentPage = res.data.page;
        } else {
          return alert(res.data.message);
        }
      });
    },
    submit() {
      if (this.email == "") {
        return alert("이름을 입력해주세요.");
      } else if (this.emailValidate(this.email) == false) {
        return alert(
          "이메일 형식이 맞지 않습니다.아래와 같이 이메일 주소 전체를 입력해주세요. service@ulmani.co.kr"
        );
      }
      let data = {
        userId: this.userId,
        isAdmin: this.isAdmin,
        email: this.email,
        username: this.username,
        mobileNo: this.mobileNo,
        job: this.job,
        isMarried: this.isMarried,
        address: this.address,
        birth: this.birth,
        gender: this.gender,
        point: this.point,
      };
      updateUserDetails(data).then((res) => {
        if (res.data.status == 200) {
          this.$notify({
            title: "Success",
            message: "회원 정보가 수정 되었습니다.",
            type: "success",
          });
        } else {
          return alert(res.data.message);
        }
      });
    },
  },
};
</script>
